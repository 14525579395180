import React from 'react'
import {
  CoverFooter,
  CoverMainWrapper,
  CoverPage,
  EditorPageDivider,
  REPORT_HEADERS,
  ReportByPurview,
  ReportDisclaimer
} from './ReportFrontCover';
import {EditLayoutButton} from './EditLayoutButton'
import {InstitutionDetailsSelector} from './InstituionDetailsSelector'
import styled, { css } from 'styled-components';
import { getColor } from '@startlibs/utils';
import { Button, Icon, Loading } from '@startlibs/components';
import { AddButton } from '../../../../components/AddButton';
import { useToggle } from '@startlibs/core';
import _ from 'lodash';
import { Field, TextInput, useConfirmDialog } from '@startlibs/form';
import { limitLength } from '../../../../utils/utils';
import { ConfirmDialog } from '../../../../hooks/useConfirmDialog';
import { isStrEmpty } from '../../../../utils';
import { useEffect } from 'react';

export const BackCoverNotesWrapper = styled.div`
  max-width: 400px;
  width: 100%;
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%,-50%);
`

export const BackCoverMainWrapper = styled.div`
  max-width: 400px;
  width: 100%;
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%,-50%);
`

export const ThankNote = styled.div`
  font-size: 24px;
  margin-bottom: 1.5rem;
  color: ${getColor('main')};
  font-weight: 600;
  text-align: center;
`

export const MarketingMessage = styled.p`
  font-size: 13px;
  // color: #969696;
  color: #636362;
  text-align: center;
`

export const LastPageNote = styled.div`
  position: relative;
  margin-top: 2rem;
  label {
    font-weight: normal;
    color: ${getColor('gray180')};
    font-size: 13px;
    padding-bottom: 0;
    margin-bottom: 0;
    border: 0px;
  }
  .field-wrapper {
    ${Icon} {
      position: absolute;
      top: .25rem;
      right: 0;
      font-size: 16px;
      color: ${getColor('gray180')};
      cursor: pointer;
    }
  }
  ${Loading} {
    position: absolute;
    top: .25rem;
    right: 1.5rem;
  }
  ${AddButton} {
    ${Loading} {
      border-color: rgba(0,0,0,0.15);
      border-top-color: ${getColor('main')};
      margin-top: 2px;
    }
  }
  ${props => props.isPreview && css`
    color: ${getColor('gray120')};
  `}
`

export const ReportBackCover = ({caseRequest,setCaseRequest,reportLayout, providerInfo, institutions, defaultInstitution, form}) => {

  const lastPageNoteLoading = useToggle()

  const confirmRemoveNote = useConfirmDialog(
    <ConfirmDialog
      action={() => {form.setValue('lastPageNote', null);return lastPageNoteLoading.wait(form.submitForm())}}
      confirm={<Button alert>Remove</Button>}
      title="Are you sure you want to remove back cover notes?"
    >
      This action cannot be undone.
  </ConfirmDialog>
  )

  const checkRemoveNote = () => {
    if (isStrEmpty(form.getValue("lastPageNote"))) {
      form.setValue('lastPageNote', null)
      lastPageNoteLoading.wait(form.submitForm())
    } else {
      confirmRemoveNote()
    }
  }

  return <CoverPage isBackCover isA4={reportLayout.paperSize === "A4"}>
    <EditorPageDivider>
      <span>Back cover start</span>
    </EditorPageDivider>

    <EditLayoutButton caseRequest={caseRequest} setCaseRequest={setCaseRequest}/>

    <BackCoverNotesWrapper>
      <ThankNote>Thank you for choosing {caseRequest?.institutionDetails?.name ? caseRequest?.institutionDetails?.name : providerInfo?.name}</ThankNote>

      <LastPageNote>
        {
          _.isString(caseRequest.reportData.lastPageNote)
            ? <div className="field-wrapper">
                <Field label="Back cover note">
                  <TextInput
                    textarea
                    transform={limitLength(800,"Back cover note text length limit reached.")}
                    onBlur={() => lastPageNoteLoading.wait(form.submitForm())}
                    path="lastPageNote"
                    placeholder="Insert your note here"
                    css="min-height: 148px;"
                  />
                  <Icon
                    icon='x' onClick={checkRemoveNote}
                  />
                </Field>
                {lastPageNoteLoading.isOpen &&  <Loading size={16} borderWidth={3} />}
              </div>
            : <AddButton
                isLoading={lastPageNoteLoading.isOpen}
                onClick={() => {
                  form.setValue('lastPageNote', caseRequest?.institutionDetails?.lastPageNote ? caseRequest.institutionDetails.lastPageNote : '')
                  lastPageNoteLoading.wait(form.submitForm())
                }}
              >
                Add back cover note
              </AddButton>
        }
      </LastPageNote>
      {/* {caseRequest?.institutionDetails?.institutionDetailsInfo?.lastPageNote && 
        <MarketingMessage>{caseRequest.institutionDetails.institutionDetailsInfo.lastPageNote}</MarketingMessage>
      } */}
    </BackCoverNotesWrapper>

    <BackCoverMainWrapper>
      <div css="text-align: right; margin-bottom: .25rem; margin-right: -1.5rem;">
        <InstitutionDetailsSelector caseRequest={caseRequest} setCaseRequest={setCaseRequest} institutions={institutions} defaultInstitution={defaultInstitution} />
      </div>
      {
        REPORT_HEADERS[reportLayout.headerLayout](caseRequest?.report?.institutionDetails || caseRequest.institutionDetails, providerInfo)
      }
    </BackCoverMainWrapper>

    <CoverFooter>
      <ReportByPurview>
        Created with Purview Expert View™
      </ReportByPurview>
      <ReportDisclaimer
        providerInfo={providerInfo}
        setCaseRequest={setCaseRequest}
        caseRequest={caseRequest}
        reportLayout={reportLayout}
      />
    </CoverFooter>

  </CoverPage>
}
