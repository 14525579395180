import {Button, ContextMenu, Dialog, Icon, Li, Loading, LoadingDiv, TransitionDiv, willUseIsSticky } from '@startlibs/components';
import {_s, getColor } from '@startlibs/utils';
import React, {useEffect, useState} from 'react'
import _, { uniq } from 'lodash/fp'
import styled from 'styled-components'
import {PurviewFooter} from '../../components/PurviewFooter'
import {Card, PageContainer} from '../../components/PageLayout';
import {getJwt} from '../../hooks/useJwt'
import { CountryRegionData } from 'react-country-region-selector'
import { Combobox, SimpleCheckbox, TextInput } from '@startlibs/form';
import { usePopupToggle, useToggle } from '@startlibs/core';
import { jwtGetFetcher, jwtPostFetcher } from '../../utils/authFetch';
import { setNotification } from '../../components/Notifications';
import { darken, transparentize } from 'polished';

const RoundButton = styled.div`
  position: relative;
  border-radius: 50%;
  margin-left: 1rem;
  background: ${props => transparentize(0.88, getColor('main')(props))};
  :hover {
    background: ${props => transparentize(0.85, getColor('main')(props))};
  }
  :active {
    background: ${props => transparentize(0.82, getColor('main')(props))};
  }
  color: ${getColor('main')};
  padding: 0.5rem;
  width: 24px;
  height: 24px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`
const CountryContainer = styled.div`
  background: #FFFFFF;
  border: 1.4px solid ${getColor('gray210')};
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  transition: all 0.2s;
  margin-top: 8px;
  flex-wrap: wrap;
  ${props => !props.expanded && `
    overflow: hidden;
  `}
`
const CountryDiv = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding:0.5rem 1rem;
  .link {
    margin-left: 0.75rem;
  }
`

const CountryHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  cursor: pointer;
  border-radius: 5px 5px 0 0;
  min-height: 48px;
  font-size: 13px;
  ${props => props.expanded && `
    position: sticky;
    top: 5.5rem;
  `}
  z-index: 10;
  background: white;
  box-shadow: 0 1px 0 0 ${getColor('gray210')};
  :hover {
    background: rgb(245,245,245);
  }
`

const StyledRow = styled.div`
  padding: 0.75rem 1rem;
  :hover {
   background: ${props => darken(0.034, '#FFFFFF')};
   cursor: pointer;
  }
  &:nth-child(odd) {
    background-color: #F7EFEF;
    :hover {
      background: ${props => darken(0.03, '#F7EFEF')};
     }
  }
  font-size: 13px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid ${getColor('gray210')};
  width: 100%;
  div{
    margin-top: 3px;
  }
`

const SearchInput = styled.div`
  position: relative;
  width: 50%;
  > input {
    word-break: normal;
    padding-right: 2.75rem;
    padding-left: 2.75rem;
    border: 1px solid ${getColor('gray210')};
  }
`

const ClearSearchButton = styled(Icon)`
  color: ${getColor('gray90')};
  font-size: 12px;
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  width: 1.5rem;
  line-height: 1.5rem;
  text-align: center;
  border-radius: 50%;
  background: rgba(0,0,0,0.1);
  :hover {
    background: #f7d3d4;
    color: ${getColor('alert')};
  }
`

const SearchIcon = styled(Icon)`
  position: absolute;
  z-index: 100;
  left: 8px;
  bottom: 7px;
  font-size: 22px;
  color: ${getColor('gray150')};
`

const EmptyList = styled.div `
  background: ${getColor('gray240')};
  color: ${getColor('gray120')};
  min-height: 10rem;
  border-radius: 5px;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 14px;
  flex-grow: 1;
`

const FlexDiv = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;  
  align-content: center;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;
  margin-bottom: 1rem;
`
const CardFooter = styled.div`
  padding: 2rem 2.5rem 2rem;
  margin: 0rem -2.5rem -2.5rem;
  border-radius: 0 0 10px 10px;
  justify-content: flex-end;
  display: flex;
  position: sticky;
  bottom: 0;
  z-index: 100;
  background: white;
  ${props => props.isSticky && `
    box-shadow: 0 -2px 2px 0 rgba(0,0,0,0.1);
  `}
`
const ExpandIcon = styled(Icon).attrs({icon:'arrow-right'})`
  transition: 0.3s ease;
  padding: 0 0.5rem;
  margin-right: 0.6rem;
  ${props => props.expanded && `
    transform: rotate(90deg);
  `}
`


export const Jurisdiction = () => {
  
  const [search, setSearch] = useState('')
  const addNew = useToggle()
  const countryList = CountryRegionData.map(country => country[0])
  // Find the index of 'United States'
  const usIndex = countryList.findIndex(country => country === 'United States');
  if (usIndex !== -1) {
    // Remove 'United States' from its current position
    const usItem = countryList.splice(usIndex, 1)[0];
    // Add 'United States' to the start of the array
    countryList.unshift(usItem);
  }
  const [selectedNewCountry, setSelectedNewCountry] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [isSaving, setIsSaving] = useState(false)
  const [WithIsSticky] = willUseIsSticky()

  const ctxMenu = usePopupToggle()
  const [originalJurisdictions, setOriginalJurisdictions] = useState([])
  const [selectedJurisdictions, setSelectedJurisdictions] = useState([])
  const [openedJurisdictions, setOpenedJurisdictions] = useState([])

  const filteredJurisdictions = selectedJurisdictions.filter(jurisdiction => {
    // If search is empty, we want to include all jurisdictions.
    if (search === '') return true;

    // Split the search term by spaces to handle multi-word searches.
    const searchTerms = search.toLowerCase().split(' ');

    // We filter the states based on the search terms.
    // We now use `startsWith` on the full state name rather than the split words.
    return jurisdiction?.allAvailableStates.some(state => {
        // We convert the state name to lower case once, to avoid doing it multiple times.
        const lowerCaseState = state.toLowerCase();
        
        // Check if all search terms are at the start of any word in the state name.
        return searchTerms.every(term => 
            lowerCaseState.startsWith(term) || 
            lowerCaseState.split(' ').some(word => word.startsWith(term))
        );
    });
}).map(jurisdiction => {
    // If search is empty, return the original jurisdiction.
    if (search === '') return jurisdiction;

    // Otherwise, we need to return a modified jurisdiction.
    const searchTerms = search.toLowerCase().split(' ');

    return {
        ...jurisdiction,
        states: jurisdiction.states.filter(state => {
            const lowerCaseState = state.toLowerCase();
            return searchTerms.every(term =>
                lowerCaseState.startsWith(term) ||
                lowerCaseState.split(' ').some(word => word.startsWith(term))
            );
        }),
        allAvailableStates: jurisdiction.allAvailableStates.filter(state => {
            const lowerCaseState = state.toLowerCase();
            return searchTerms.every(term =>
                lowerCaseState.startsWith(term) ||
                lowerCaseState.split(' ').some(word => word.startsWith(term))
            );
        })
    };
});

  useEffect(() => {
  
    jwtGetFetcher(getJwt())("/api/admin/acceptedJurisdictions").then(jurisdictions => {

      const transformedJurisdictions = jurisdictions.map(jurisdiction => {
        let newJurisdiction = _.cloneDeep(jurisdiction); // Deep clone the object
        
        newJurisdiction.allAvailableStates = 
          CountryRegionData
            .find(country => {
              return country[0] === jurisdiction.country;
            })[2].split("|").map(state => state.split("~")[0]);
        
        // newJurisdiction.isOpen =  jurisdictions.length == 1 ? true : false;
        if(jurisdictions.length == 1){
          setOpenedJurisdictions([newJurisdiction.country])
        }
        
        
        return newJurisdiction;
      });
      
      setSelectedJurisdictions(transformedJurisdictions);
      setOriginalJurisdictions(_.cloneDeep(transformedJurisdictions)); // Deep clone the array to ensure unique references
    })
    .catch(e => {
      console.log(e)
      setNotification({ type: "alert", msg: "Failed to load accepted jurisdictions" })
    })
    .finally(() => {
      setIsLoading(false)
    })
  }, [])

  const selectAll = (country) => {
    setSelectedJurisdictions(selectedJurisdictions => {
      return selectedJurisdictions.map(jurisdiction => {
        if (jurisdiction.country === country) {
          // If the search is empty, select all states, otherwise filter them
          if (search === '') {
            jurisdiction.states = [...jurisdiction.allAvailableStates];
          } else {
            // Handle multi-word search terms
            const searchTerms = search.toLowerCase().split(' ');
            jurisdiction.states = jurisdiction.states.concat(jurisdiction.allAvailableStates.filter(state => {
              const lowerCaseState = state.toLowerCase();
              // The state name must start with all search terms or individual words in the state name must start with any of the search terms
              return searchTerms.every(term =>
                lowerCaseState.startsWith(term) ||
                lowerCaseState.split(' ').some(word => word.startsWith(term))
              );
            })).filter((state, index, self) => self.indexOf(state) === index); // ensures that the state is only added if it's the first occurrence in the array
          }
        }
        return jurisdiction;
      })
    });
  };
  
  const deselectAll = (country) => {
    setSelectedJurisdictions(selectedJurisdictions => {
      return selectedJurisdictions.map(jurisdiction => {
        if (jurisdiction.country === country) {
          // If the search is empty, deselect all states
          if (search === '') {
            jurisdiction.states = [];
          } else {
            // Split the search term by spaces to handle multi-word searches
            const searchTerms = search.toLowerCase().split(' ');
  
            // Only remove states that match the search terms
            jurisdiction.states = jurisdiction.states.filter(state => {
              const lowerCaseState = state.toLowerCase();
              // A state is kept if it does not start with the search term and if no word in the state name starts with the search term
              return !searchTerms.every(term =>
                lowerCaseState.startsWith(term) ||
                lowerCaseState.split(' ').some(word => word.startsWith(term))
              );
            });
          }
        }
        return jurisdiction;
      });
    });
  };
  
  
  return <>
    <PageContainer css="max-width: 100%;">
      <Card>
        <h3>Accepted jurisdictions</h3>
        <p>Choose the countries and jurisdictions from which you wish to receive case submissions.</p>
        <hr css="margin-top:2rem;"/>
        
        {isLoading 
          ? <EmptyList css="background:white;"><Loading size={36} borderWidth={5} aboslute/></EmptyList>
          : <>
            <FlexDiv>
              <SearchInput>
                <SearchIcon icon="search" />
                <TextInput raw
                  value={search}
                  setValue={setSearch}
                  placeholder="Search jurisdictions"
                />
                {search.length > 0 && <ClearSearchButton icon="x" onClick={() => {
                  setSearch('')
                }} />}
              </SearchInput>
              <Button highlight onClick={addNew.open} css="min-width:12rem;">Add country</Button>
            </FlexDiv>
            {filteredJurisdictions && filteredJurisdictions.map(jurisdiction => {
                
              return <CountryContainer key={jurisdiction.country} 
                  // expanded={jurisdiction.isOpen}
                  expanded={openedJurisdictions.find(j => j === jurisdiction.country)}
                >
                  <CountryHeader 
                  // expanded={jurisdiction.isOpen} 
                  expanded={openedJurisdictions.find(j => j === jurisdiction.country)}
                  onClick={() =>{
                    setSelectedJurisdictions(selectedJurisdictions => {
                      return selectedJurisdictions.map(j => {
                        if (j.country === jurisdiction.country) {
                          // j.isOpen = !jurisdiction.isOpen
                          let isOpen = openedJurisdictions.find(j => j === jurisdiction.country)
                          if(isOpen){
                            setOpenedJurisdictions(openedJurisdictions.filter(j => j !== jurisdiction.country))
                          }else{
                            setOpenedJurisdictions([...openedJurisdictions, jurisdiction.country])
                          }
                        }
                        return j
                      })
                    })
                  }}>
                    <CountryDiv>
                      <ExpandIcon 
                        // expanded={jurisdiction.isOpen}
                        expanded={openedJurisdictions.find(j => j === jurisdiction.country)}
                      />
                      <h4 style={{
                        fontSize: '14px',
                        fontWeight: 'bold',
                        margin: '0',
                      }}>{jurisdiction.country}</h4>
                    </CountryDiv>
                    <CountryDiv>
                      <span>{jurisdiction.states.length}/{jurisdiction.allAvailableStates.length} jurisdictions selected</span>
                      <TransitionDiv block>{
                        openedJurisdictions.find(j => j === jurisdiction.country) && <><a className='link' onClick={(e) => {deselectAll(jurisdiction.country);e.stopPropagation();}}>Deselect all</a> <a className='link' onClick={(e) => {selectAll(jurisdiction.country);e.stopPropagation();}}>Select all</a></> }</TransitionDiv>
                        {/* jurisdiction.isOpen && <><a className='link' onClick={(e) => {deselectAll(jurisdiction.country);e.stopPropagation();}}>Deselect all</a> <a className='link' onClick={(e) => {selectAll(jurisdiction.country);e.stopPropagation();}}>Select all</a></> }</TransitionDiv> */}
                      <RoundButton onClick={(e) => {
                        e.stopPropagation()
                       ctxMenu.openWith(
                         jurisdiction.country
                       )
                      }}>
                        <Icon icon="arrow-down"/>
                        {ctxMenu.isOpen == jurisdiction.country && <ContextMenu>
                          <Li onClick={() => {
                            setSelectedJurisdictions(selectedJurisdictions => {
                              return selectedJurisdictions.filter(j => {
                                return j.country !== jurisdiction.country
                              })
                            })
                          }} label="Remove country" icon="delete"/>
                        </ContextMenu>}
                      </RoundButton>
                    </CountryDiv>
                  </CountryHeader>
                  <TransitionDiv block css="width:100%;">{
                    openedJurisdictions.find(j => j === jurisdiction.country) && jurisdiction?.allAvailableStates?.map(state => {
                    // jurisdiction.isOpen && jurisdiction?.allAvailableStates?.map(state => {
                      return <StyledRow key={state} onClick={(e) => {
                        e.stopPropagation()
                        e.preventDefault()
                        jurisdiction.states.indexOf(state) >= 0
                          ? setSelectedJurisdictions(selectedJurisdictions => {
                            return selectedJurisdictions.map(j => {
                              if (j.country === jurisdiction.country) {
                                j.states = j.states.filter(s => s !== state)
                              }
                              return j
                            })
                          })
                          : setSelectedJurisdictions(selectedJurisdictions => {
                            return selectedJurisdictions.map(j => {
                              if (j.country === jurisdiction.country) {
                                j.states.push(state)
                              }
                              return j
                            })
                          })
                      }}>{state}
                        <SimpleCheckbox 
                          raw
                          key={state}
                          value={jurisdiction.states.indexOf(state) >= 0}
                          onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                            jurisdiction.states.indexOf(state) >= 0
                              ? setSelectedJurisdictions(selectedJurisdictions => {
                                return selectedJurisdictions.map(j => {
                                  if (j.country === jurisdiction.country) {
                                    j.states = j.states.filter(s => s !== state)
                                  }
                                  return j
                                })
                              })
                              : setSelectedJurisdictions(selectedJurisdictions => {
                                return selectedJurisdictions.map(j => {
                                  if (j.country === jurisdiction.country) {
                                    j.states.push(state)
                                  }
                                  return j
                                })
                              })
                          }}
                        />
                    </StyledRow>
                    })
                  }
                  </TransitionDiv>
              </CountryContainer>
            })}
            {filteredJurisdictions.length === 0 ?
              search.length > 0 
                ? <EmptyList>There are no jurisdictions matching your search criteria</EmptyList>
                : <EmptyList>Jurisdictions not added yet</EmptyList>
              : null
            }
        </>}
        {!isLoading && <WithIsSticky bottom>{(sentinel, isSticky) => <>
        {!_.isEqual(selectedJurisdictions, originalJurisdictions) && <CardFooter isSticky={isSticky} >
          <Button onClick={() => {
            setSelectedJurisdictions(() => {
              const clonedJurisdictions = originalJurisdictions.map(jurisdiction => {
                let newJurisdiction = _.cloneDeep(jurisdiction); // Deep clone the object
                return newJurisdiction;
              });
              return _.cloneDeep(clonedJurisdictions)
            })
            setNotification('All changes discarded')
          }}>Discard changes</Button>
          <Button highlight isLoading={isSaving} 
          disabled={_.isEqual(selectedJurisdictions, originalJurisdictions)}
          onClick={() => {
            setIsSaving(true)
            jwtPostFetcher(getJwt())('/api/admin/acceptedJurisdictions', 
              selectedJurisdictions.map(jurisdiction => {
                return {
                  country: jurisdiction.country,
                  states: jurisdiction.states
                }
              })
            ).then(() => {
              setOriginalJurisdictions(() => {
                const clonedJurisdictions = selectedJurisdictions.map(jurisdiction => {
                  let newJurisdiction = _.cloneDeep(jurisdiction); // Deep clone the object
                  return newJurisdiction;
                });
                return _.cloneDeep(clonedJurisdictions)
              })
              setNotification('Jurisdictions saved')
            })
            .catch(err => {
              setNotification({ type: "alert", msg: <>It was not possible to save the changes. Please try again later.</> })
            })
            .finally(() => {
              setIsSaving(false)
            })
          }}>Save</Button>
        </CardFooter>}
        {sentinel("margin-top:2rem;")}
        </>
        }</WithIsSticky>
        }
      </Card>
    </PageContainer>
    {addNew.isOpen && <Dialog
      closeDialog={addNew.close}
      title="Add country"
      footer={
        <>
          <Button onClick={addNew.close}>Cancel</Button>
          <Button highlight disabled={!selectedNewCountry || selectedNewCountry === ''}
            onClick={() => {
              setSelectedJurisdictions(selectedJurisdictions => {

                const newSelected = selectedJurisdictions.map(
                  (jurisdiction) => {
                    return {
                      ...jurisdiction,
                    }
                  }
                )
                
                newSelected
                .push({
                  country: selectedNewCountry,
                  states: CountryRegionData.find(country => 
                    country[0] === selectedNewCountry
                  )[2].split("|").map(state => state.split("~")[0]),
                  allAvailableStates: CountryRegionData.find(country => 
                    country[0] === selectedNewCountry
                  )[2].split("|").map(state => state.split("~")[0]),
                })
                setOpenedJurisdictions([selectedNewCountry])
                return newSelected
              })
              addNew.close()
              setSelectedNewCountry('')}
            }
          >
            Add country
          </Button>
        </>
      }>
        <Combobox
          className="combobox"
          placeholder="Please select country"
          label={"Select a country"}
          raw
          value={selectedNewCountry}
          setValue={setSelectedNewCountry}
          options={countryList}
        />
      </Dialog>
    }
    <PurviewFooter/>
  </>
}
