import {Button, ContextMenu, Dialog, DraggableItem, Icon, Li, Loading, LoadingDiv, SplitColumnsContainer, TransitionDiv, willUseIsSticky } from '@startlibs/components';
import {_s, getColor } from '@startlibs/utils';
import React, {useEffect, useRef, useState} from 'react'
import _, { uniq } from 'lodash/fp'
import styled, { css } from 'styled-components'
import {PurviewFooter} from '../../components/PurviewFooter'
import {Card, PageContainer} from '../../components/PageLayout';
import {getJwt} from '../../hooks/useJwt'
import { CountryRegionData } from 'react-country-region-selector'
import { Combobox, Errors, FormValue, SimpleCheckbox, TextInput, WithForm } from '@startlibs/form';
import { usePopupToggle, useToggle } from '@startlibs/core';
import { jwtGetFetcher, jwtPostFetcher } from '../../utils/authFetch';
import { setNotification } from '../../components/Notifications';
import { darken, transparentize } from 'polished';
import { ConfirmDialog, useConfirmDialog } from '../../hooks/useConfirmDialog';
import { RemoveIcon } from '../../request/forms/Answer';
import { QuestionList } from '../../components/QuestionList';
import { buildValidation, required } from '../../utils/validation';
import { PriceInput } from '../../components/PriceInput';
import { DragDropHelp } from '../steps/ExpertReviewStep';
import { isEmpty } from 'lodash';

const PaymentFieldsWrapper = styled(Card)`
  position: relative;
  border: 0px solid ${getColor('gray210')};
  padding: 0;
  margin-top: 1rem;
`

const CustomFieldContainer = styled.div`
  position: relative;
  margin-bottom: 2rem;
  box-shadow: 0 0 0 1px ${getColor('gray210')};
  padding: 1.5rem 1.5rem 0.5rem;
  background: ${getColor('gray240')};
  border-radius: 6px;
`

const RoundButton = styled.div`
  position: relative;
  border-radius: 50%;
  margin-left: 1rem;
  background: ${props => transparentize(0.88, getColor('main')(props))};
  :hover {
    background: ${props => transparentize(0.85, getColor('main')(props))};
  }
  :active {
    background: ${props => transparentize(0.82, getColor('main')(props))};
  }
  color: ${getColor('main')};
  padding: 0.5rem;
  width: 24px;
  height: 24px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`
const CountryContainer = styled.div`
  background: #FFFFFF;
  border: 1.4px solid ${getColor('gray210')};
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  transition: all 0.2s;
  margin-top: 8px;
  flex-wrap: wrap;
  ${props => !props.expanded && `
    overflow: hidden;
  `}
`
const CountryDiv = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding:0.5rem 1rem;
  .link {
    margin-left: 0.75rem;
  }
`

const CountryHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  cursor: pointer;
  border-radius: 5px 5px 0 0;
  min-height: 48px;
  font-size: 13px;
  ${props => props.expanded && `
    position: sticky;
    top: 5.5rem;
  `}
  z-index: 10;
  background: white;
  box-shadow: 0 1px 0 0 ${getColor('gray210')};
  :hover {
    background: rgb(245,245,245);
  }
`

const StyledRow = styled.div`
  padding: 0.75rem 1rem;
  :hover {
   background: ${props => darken(0.034, '#FFFFFF')};
   cursor: pointer;
  }
  &:nth-child(odd) {
    background-color: #F7EFEF;
    :hover {
      background: ${props => darken(0.03, '#F7EFEF')};
     }
  }
  font-size: 13px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid ${getColor('gray210')};
  width: 100%;
  div{
    margin-top: 3px;
  }
`

const SearchInput = styled.div`
  position: relative;
  width: 50%;
  > input {
    word-break: normal;
    padding-right: 2.75rem;
    padding-left: 2.75rem;
    border: 1px solid ${getColor('gray210')};
  }
`

const ClearSearchButton = styled(Icon)`
  color: ${getColor('gray90')};
  font-size: 12px;
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  width: 1.5rem;
  line-height: 1.5rem;
  text-align: center;
  border-radius: 50%;
  background: rgba(0,0,0,0.1);
  :hover {
    background: #f7d3d4;
    color: ${getColor('alert')};
  }
`

const SearchIcon = styled(Icon)`
  position: absolute;
  z-index: 100;
  left: 8px;
  bottom: 7px;
  font-size: 22px;
  color: ${getColor('gray150')};
`

const EmptyList = styled.div `
  background: ${getColor('gray240')};
  color: ${getColor('gray120')};
  min-height: 10rem;
  border-radius: 5px;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 14px;
  flex-grow: 1;
`

const FlexDiv = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;  
  align-content: center;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;
  margin-bottom: 1rem;
  width: 100%;
`
const CardFooter = styled.div`
  padding: 2rem 2.5rem 2rem;
  margin: 0rem -2.5rem -2.5rem;
  border-radius: 0 0 10px 10px;
  position: sticky;
  bottom: 0;
  z-index: 100;
  background: white;
  ${props => props.isSticky && `
    box-shadow: 0 -2px 2px 0 rgba(0,0,0,0.1);
  `}
  .buttonsContainer {
    justify-content: flex-end;
    display: flex;
  }
`
const ExpandIcon = styled(Icon).attrs({icon:'arrow-right'})`
  transition: 0.3s ease;
  padding: 0 0.5rem;
  margin-right: 0.6rem;
  ${props => props.expanded && `
    transform: rotate(90deg);
  `}
`


const GET_EMPTY_CUSTOM_FIELD = (formValue) => {
  
  return  formValue 
    ? { 
      description: '',
      feeValue: '',
      id: Date.now().toString(36) 
    } 
    : null
}

const preValidation = buildValidation({
  // 'mrn': (v) => v != null && !v.length && required(),
  'paymentFees': (v) => v.some(f => !f.description || !f.feeValue) && required(),
})

export const PaymentFees = () => {
  
  
  const [defaultFees, setDefaultFees] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [isSaving, setIsSaving] = useState(false)
  const [WithIsSticky] = willUseIsSticky()

  const formRef = useRef()
  const submitRef = useRef()
  const [isSubmiting, setIsSubmiting] = useState(false)

  useEffect(() => {
  
    jwtGetFetcher(getJwt())("/api/fee")
      .then(fees => {
        const sortedFees = fees.sort((a,b) => a.description < b.description ? -1 : 1)
        setDefaultFees(sortedFees)
      })
      .catch(err => console.log(err))
      .finally(() => setIsLoading(false))

  }, [])

  
  return <>
    <PageContainer css="max-width: 100%;">
      <Card>
        <h3>Payment fees</h3>
        <p>Add and manage default fees</p>
        <hr css="margin-top:2rem;"/>
        
        {isLoading 
          ? <EmptyList css="background:white;"><Loading size={36} borderWidth={5} aboslute/></EmptyList>
          : <>
            
            <WithForm
                // alwaysSave
                // transform={(values) => {
                //   // save it in alphabetical order by description
                //   values.paymentFees.sort((a,b) => a.description < b.description ? -1 : 1)
                //   return {
                //     paymentFees: values.paymentFees
                //   }
                // }}
                values={{paymentFees: defaultFees}}
                preValidation={preValidation}
                // action={action}
                action={(values) => {
                  setIsSaving(true)
                  // values.paymentFees.sort((a,b) => a.description < b.description ? -1 : 1)
                  return jwtPostFetcher(getJwt())("/api/fee", values.paymentFees ,{method: 'PUT'})
                }}
                onSuccess={(values) => {
                  setNotification("All changes saved")
                  setIsSaving(false)
                }}
                ref={formRef}
                onFailure={() => {
                  setIsSaving(false)
                }}
              >{form => <>
                  <FormValue path="paymentFees">{paymentFees => <>
                    {paymentFees.length == 0 && <EmptyList css="margin-top: 1rem;">No default fee added yet</EmptyList>}
                  </>}</FormValue>
                  {/* {defaultFees.length == 0 &&  <EmptyList>No default fee added yet</EmptyList>} */}
                  <PaymentFields
                    form={form}
                    requestId={1}
                  />
                  
                  {form.hasChanged && !isLoading && <WithIsSticky bottom>{(sentinel, isSticky) => <>
                    <CardFooter isSticky={isSticky} >
                      <Errors css="margin-bottom: 1rem; width: 100%;"/>  
                      <div className="buttonsContainer">
                        <Button onClick={() => {
                          form.resetForm()
                          form.clearErrors()
                        }}>Discard changes</Button>
                        <Button highlight 
                          ref={submitRef} 
                          type='submit'
                          isLoading={isSaving} 
                        >Save</Button>
                      </div>
                    </CardFooter>
                    {sentinel("margin-top:2rem;")}
                    </>
                    }</WithIsSticky>
                    }
              </>}
            </WithForm>
        </>}
        
      </Card>
    </PageContainer>
    <PurviewFooter/>
  </>
}


const PaymentFields = ({ form, readOnly, location, requestId }) => {

  const setItemOrder = (from, to) => {
    // return
    const path = "paymentFees"
    const oldOrder = form.getValue(path)
    const newOrder = _s.moveElement(from, to, oldOrder)
    form.setValue(path, newOrder)
  }

  return <PaymentFieldsWrapper>
    {/* <FormValue path="paymentFees">{additionalInfos =>
      additionalInfos?.length > 1 && <DragDropHelp><span>Drag and drop items to reorder</span></DragDropHelp>
    }</FormValue> */}
    <FormValue path="paymentFees">{additionalInfos =>
      <QuestionList
        disabled={readOnly}
        path="paymentFees"
        addLabel="Add fee"
        // getEmpty={GET_EMPTY_CUSTOM_FIELD}
        getEmpty={() => GET_EMPTY_CUSTOM_FIELD(additionalInfos)}
        limit={50}
        setItemOrder={setItemOrder}

      >{(field, i) =>
        <DraggableItem
          DragTag={PaymentFieldsInputs}
          submitted={readOnly}
          i={i} key={field.id}
          additionalInfos={additionalInfos}
          form={form}
          isAdmin
          path={['paymentFees']}
          requestId={requestId}
          isDraggable={false}
        />
        }</QuestionList>
    }</FormValue>
  </PaymentFieldsWrapper>
}

export const PaymentFieldsInputs = ({ path, autoFocus, className, children, submitted, form, i, onMouseDown, onTouchStart, onBlur, additionalInfos, isAdmin = false, requestId }) => {
  const confirmRemove = () => form.setValue(path, _.pullAt([i]))

  const [value, setValue] = useState(additionalInfos ? additionalInfos[i].content : form.getValue([...path, i, "content"]))
  const [focus, setFocus] = useState(false)
  const handleEditorFocus = (event, change) => {
    setFocus(true)
  };

  const confirmDelete = useConfirmDialog(
    <ConfirmDialog
      title="Confirm fee deletion"
      confirm={<Button alert>Delete</Button>}
      action={confirmRemove}
    >
      <p><b>Are you sure you want to delete this fee?</b></p>
      <p>Once confirmed this cannot be undone.</p>
    </ConfirmDialog>
  )

  const removeQuestion = () => {
    const field = form.getValue([...path, i])
    if (field.description || field.feeValue) {
      confirmDelete()
    } else {
      confirmRemove()
    }
  }

  return <CustomFieldContainer
    onMouseDown={onMouseDown}
    onTouchStart={onTouchStart}
    submitted={submitted}
    className={className}
  >
          
    <div data-drag={false} onBlur={onBlur} >
      <SplitColumnsContainer margin="big">
        <TextInput
          className='fs-exclude'
          path={[...path, i, "description"]}
          placeholder="Ex: Consultation fee"
          label="Fee description"
          hasErrors={form.hasErrors('paymentFees') && isEmpty(form.getValue([...path, i, "description"]))}
          mandatory
        />
        <PriceInput
          label="Out of pocket fee"
          path={[...path, i, "feeValue"]}
          placeholder="Ex: 300.00"
          hasErrors={form.hasErrors('paymentFees') && isEmpty(form.getValue([...path, i, "feeValue"]))}
          mandatory
        />
      </SplitColumnsContainer>
    </div>
    {!submitted && <RemoveIcon data-drag={false} icon='x' addedByExpert onClick={removeQuestion} />}
    {children}
  </CustomFieldContainer>
}
