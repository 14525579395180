import {Button, DropdownIcon, Icon} from '@startlibs/components';
import {getColor, media} from '@startlibs/utils'
import styled, { css } from 'styled-components';

export const EmptyListPlaceholder = styled.div `
  padding: 5rem 1rem;
  text-align: center;
  background-color: rgba(0,0,0,0.05);
  border-radius: 5px;
  color: rgba(0,0,0,0.6);
  font-size: 14px;
  font-weight: 600;
  margin-top: 1.5rem;
  border: 1px solid ${getColor('gray210')};
  ${props => props.onDialog &&`
    padding: 2rem 1rem;
    margin-top: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-top: 0;
    font-size: 13px;
  `}
`
export const SearchInput = styled.div`
  width: 20rem;
  position: relative;
  ${Icon} {
    font-size: 20px;
    color: rgba(0,0,0,0.25);
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 0.75rem;
    transform: translateY(-50%);
  }
  input {
    padding-left: 2.5rem;
  }
  ${media.max(520)`
    width: 100%;
  `}
`

export const StyledTable = styled.div`
  margin-top: 1.5rem;
  border: 1px solid ${getColor('gray210')};
  border-radius: 5px;
  overflow: hidden;
  ${media.max(840)`
    border: 0px;
  `}
`

export const StyledRow = styled.div`
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${getColor('gray210')};
  padding: 0 .5rem;
  &:nth-child(even) {
    background-color: #F7EFEF;
  }
  &:last-child {
    border-bottom: 0px;
  }
  > div {
    padding: 1rem .75rem;
    flex-basis: 20%;
    &.actions {
      display: flex;
      justify-content: flex-end;
      word-break: keep-all;
      white-space: nowrap;
      flex-shrink: 0;
      > a ~ a {
        margin-left: .75rem;
      }
    }
  }
  ${media.max(840)`
    display: block;
    border: 1px solid ${getColor('gray210')};
    border-radius: 5px;
    padding: 1.5rem 1.5rem 0;
    font-size: 13px;
    position: relative;
    & ~ & {
      margin-top: 1rem;
    }
    &:last-child {
      border: 1px solid ${getColor('gray210')};
    }
    &:nth-child(even) {
      background-color: white;
    }
    > div {
      padding: 0;
      &:before {
        font-weight: 600;
        display: block;
      }
      &.actions {
        display: flex;
        padding: 0.5rem;
        margin: 0 -1.5rem;
        background: rgba(41, 122, 163, 0.07);
        clear: both;
        > * {
          flex-grow: 1;
          text-align: center;
          flex-shrink: 0;
        }
      }
    }
  `}
`

export const StyledTableHead = styled(StyledRow)`
  font-weight: 600;
  ${Button} {
    font-weight: 400;
  }
  ${media.max(840)`
    display: none;
  `}
`

export const TextButton = styled.a`
  text-decoration: none;
  color: ${getColor('main')};
  background-color: rgba(0, 139, 210, 0.1);
  padding: 0.5rem 0.75rem;
  font-weight: 600;
  border-radius: 5px;
  user-select: none;
  position:relative;
  display: flex;
  align-items: center;
  justify-content: center;
  & ~ & {
    margin-left: 0.25rem;
  }
  :hover {
    background-color: rgba(0, 139, 210, 0.15);
  }
  :active {
    background-color: rgba(0, 139, 210, 0.2);
  }
  ${media.max(840)`
    & ~ & {
      margin-left: 0.5rem;
    }
  `}
  ${props => props.withIcon && css`
    display: flex;
    align-items: center;
    ${Icon} {
      font-size: 18px;
      margin-right: .25rem;
    }
  `}
`

export const DropdownButton = styled(TextButton)`
  padding: 0.25rem;
  width: 2rem;
  text-align: center;
  ${DropdownIcon} {
    font-size: 12px;
    margin-left: 0;
  }
  ${media.max(840)`
    max-width: 6rem;
  `}
  ${media.max(520)`
    max-width: 4rem;
  `}
`
